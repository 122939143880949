define("nypr-ui/templates/components/nypr-card/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fcOiZPE4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"title\",\"button\"],[[25,\"component\",[\"nypr-card/title\"],[[\"text\"],[[20,[\"title\"]]]]],[25,\"component\",[\"nypr-card/button\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"nypr-card/title\",null,[[\"text\"],[[20,[\"title\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"buttonAction\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"nypr-card/button\",null,[[\"click\",\"text\"],[[25,\"action\",[[19,0,[]],[20,[\"buttonAction\"]]],null],[20,[\"buttonText\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-card/header.hbs"
    }
  });

  _exports.default = _default;
});