define("nypr-ui/templates/components/nypr-tabs/nypr-tabs-tablist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3PX2FXaw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"tab\"],[[25,\"component\",[\"ivy-tabs-tab\"],[[\"tabList\"],[[19,0,[]]]]]]]]]],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"tablist-buffer\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-tabs/nypr-tabs-tablist.hbs"
    }
  });

  _exports.default = _default;
});