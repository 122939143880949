define("nypr-audio-services/templates/components/player-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JFIyBULS",
    "block": "{\"symbols\":[\"props\",\"listen\",\"index\"],\"statements\":[[4,\"if\",[[20,[\"listens\"]]],null,{\"statements\":[[1,[18,\"clear-history\"],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"liquid-bind\",[[25,\"hash\",null,[[\"listens\"],[[20,[\"listens\"]]]]]],null,{\"statements\":[[6,\"div\"],[9,\"class\",\"list list--borderflip\"],[7],[0,\"\\n\"],[4,\"each\",[[19,1,[\"listens\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"list-item\"],[7],[0,\"\\n   \"],[1,[25,\"queue-listitem\",null,[[\"isCurrent\",\"dataId\",\"story\",\"playContext\",\"removeAction\"],[[25,\"and\",[[25,\"eq\",[[19,3,[]],0],null],[25,\"eq\",[[19,2,[\"story\",\"id\"]],[20,[\"dj\",\"currentContentId\"]]],null]],null],[19,2,[\"id\"]],[19,2,[\"story\"]],\"history\",[25,\"action\",[[19,0,[]],\"removeFromHistory\",[19,2,[\"story\",\"id\"]]],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[2,3]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"queuelist-empty\"],[7],[0,\"Your listening history is empty… Start listening!\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/player-history.hbs"
    }
  });

  _exports.default = _default;
});