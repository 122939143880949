define("nypr-audio-services/templates/components/stream-banner/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIkvFpGV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[20,[\"activeStream\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"activeStream\",\"currentComposer\",\"name\"]],[20,[\"activeStream\",\"currentPiece\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"h2\"],[9,\"class\",\"h2\"],[7],[1,[20,[\"activeStream\",\"currentComposer\",\"name\"]],false],[8],[0,\"\\n    \"],[6,\"h3\"],[9,\"class\",\"h3\"],[7],[1,[20,[\"activeStream\",\"currentPiece\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"activeStream\",\"currentShow\",\"showUrl\"]]],null,{\"statements\":[[0,\"      \"],[6,\"h2\"],[9,\"class\",\"h2\"],[7],[6,\"a\"],[10,\"href\",[26,[[20,[\"activeStream\",\"currentShow\",\"showUrl\"]]]]],[7],[1,[20,[\"activeStream\",\"currentShow\",\"showTitle\"]],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"h2\"],[9,\"class\",\"h2\"],[7],[1,[20,[\"activeStream\",\"currentShow\",\"showTitle\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[20,[\"activeStream\",\"currentShow\",\"episodeTitle\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"activeStream\",\"currentShow\",\"episodeUrl\"]]],null,{\"statements\":[[0,\"        \"],[6,\"h3\"],[9,\"class\",\"h3\"],[7],[6,\"a\"],[10,\"href\",[26,[[20,[\"activeStream\",\"currentShow\",\"episodeUrl\"]]]]],[7],[1,[20,[\"activeStream\",\"currentShow\",\"episodeTitle\"]],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"h3\"],[9,\"class\",\"h3\"],[7],[1,[20,[\"activeStream\",\"currentShow\",\"episodeTitle\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/stream-banner/details.hbs"
    }
  });

  _exports.default = _default;
});