define("liquid-fire/templates/components/liquid-bind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "URegOfHE",
    "block": "{\"symbols\":[\"container\",\"version\",\"version\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"containerless\"]]],null,{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\",\"class\"],[[20,[\"value\"]],[20,[\"use\"]],[20,[\"rules\"]],[20,[\"forwardMatchContext\"]],[20,[\"versionEquality\"]],true,[20,[\"class\"]]]],{\"statements\":[[4,\"if\",[[22,4]],null,{\"statements\":[[11,4,[[19,3,[]]]]],\"parameters\":[]},{\"statements\":[[1,[19,3,[]],false]],\"parameters\":[]}]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-container\",null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[20,[\"containerId\"]],[20,[\"class\"]],[20,[\"growDuration\"]],[20,[\"growPixelsPerSecond\"]],[20,[\"growEasing\"]],[20,[\"shrinkDelay\"]],[20,[\"growDelay\"]],[20,[\"enableGrowth\"]]]],{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\"],[[20,[\"value\"]],[19,1,[]],[20,[\"use\"]],[20,[\"rules\"]],[20,[\"forwardMatchContext\"]],[20,[\"versionEquality\"]],true]],{\"statements\":[[4,\"if\",[[22,4]],null,{\"statements\":[[11,4,[[19,2,[]]]]],\"parameters\":[]},{\"statements\":[[1,[19,2,[]],false]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-bind.hbs"
    }
  });

  _exports.default = _default;
});