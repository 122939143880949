define("nypr-account-settings/templates/components/nypr-account-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Val9Wt5E",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"title\",\"button\"],[[25,\"component\",[\"nypr-account-modal/title\"],null],[25,\"component\",[\"nypr-account-modal/close-button\"],[[\"click\"],[[25,\"action\",[[19,0,[]],[20,[\"closeAction\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-account-modal/header.hbs"
    }
  });

  _exports.default = _default;
});