define("nypr-audio-services/templates/components/stream-banner/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x4QE0qEw",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"stream-banner__trigger-label\"],[7],[0,\"Change stream\"],[8],[0,\"\\n\"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"icon\",\"down-caret\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/stream-banner/trigger.hbs"
    }
  });

  _exports.default = _default;
});