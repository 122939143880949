define("nypr-ui/templates/components/nypr-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IA51iHVO",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[6,\"label\"],[10,\"for\",[26,[[18,\"fieldname\"]]]],[9,\"class\",\"nypr-input-label\"],[7],[1,[18,\"label\"],true],[8],[0,\"\\n\"],[1,[25,\"input\",[[25,\"-input-type\",[[20,[\"type\"]]],null]],[[\"name\",\"id\",\"placeholder\",\"type\",\"class\",\"value\",\"disabled\",\"focus-in\",\"focus-out\",\"change\",\"input\"],[[20,[\"fieldname\"]],[20,[\"fieldname\"]],[20,[\"placeholder\"]],[20,[\"type\"]],\"nypr-input\",[20,[\"value\"]],[20,[\"disabled\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"focused\"]]],null],true],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"blurred\"]]],null],true],null],[20,[\"onChange\"]],[20,[\"onInput\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"showValidMark\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-input-validmark\",\"checkmark\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"nypr-input-footer\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"helplinkUrl\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"nypr-input-helplink\"],[7],[0,\"\\n      \"],[6,\"a\"],[10,\"href\",[18,\"helplinkUrl\"],null],[7],[1,[18,\"helplinkText\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"hasError\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"nypr-input-error\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"errors\"]]],null,{\"statements\":[[0,\"        \"],[1,[19,1,[]],true],[6,\"br\"],[7],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"showAdvice\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"nypr-input-advice\"],[7],[1,[18,\"clue\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-input.hbs"
    }
  });

  _exports.default = _default;
});