define("nypr-ui/templates/components/nypr-brick-row-blowout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pa05okUE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"main\",\"column\"],[[25,\"component\",[\"nypr-brick-item\"],null],[25,\"component\",[\"nypr-brick-row-blowout/column\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"nypr-brick-item\",null,[[\"item\"],[[20,[\"items\",\"0\"]]]]],false],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",[26,[\"row__col \",[25,\"if\",[[20,[\"flip\"]],\"flipped\"],null]]]],[7],[0,\"\\n    \"],[1,[25,\"nypr-brick-item\",null,[[\"item\"],[[20,[\"items\",\"1\"]]]]],false],[0,\"\\n    \"],[1,[25,\"nypr-brick-item\",null,[[\"item\"],[[20,[\"items\",\"2\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-brick-row-blowout.hbs"
    }
  });

  _exports.default = _default;
});