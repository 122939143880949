define("nypr-ui/templates/components/nypr-story/playlist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mpebbit/",
    "block": "{\"symbols\":[\"song\"],\"statements\":[[6,\"section\"],[9,\"class\",\"list\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"list-heading\"],[7],[0,\"\\n    \"],[6,\"h3\"],[9,\"class\",\"h5\"],[7],[0,\"Music Playlist\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"ol\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"playlist\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[9,\"class\",\"list-item\"],[7],[0,\"\\n        \"],[6,\"h4\"],[9,\"class\",\"h3 flush\"],[7],[1,[19,1,[\"title\"]],true],[8],[0,\"\\n\"],[4,\"if\",[[19,1,[\"artists\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"text flush\"],[7],[0,\"Artist: \"],[1,[19,1,[\"artists\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[19,1,[\"album\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"text flush\"],[7],[0,\"Album: \"],[1,[19,1,[\"album\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[19,1,[\"label\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"text flush\"],[7],[0,\"Label: \"],[1,[19,1,[\"label\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[19,1,[\"asin\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"text flush\"],[7],[6,\"a\"],[10,\"href\",[26,[\"http://www.amazon.com/exec/obidos/ASIN/\",[19,1,[\"asin\"]],\"/wnycorg-20\"]]],[9,\"target\",\"_blank\"],[7],[0,\"Buy on Amazon\"],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-story/playlist.hbs"
    }
  });

  _exports.default = _default;
});