define("nypr-ui/templates/components/dropdown-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Louhs716",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"renderInPlace\"],[true]],{\"statements\":[[0,\"  \"],[11,2,[[25,\"hash\",null,[[\"button\",\"body\"],[[25,\"component\",[[19,1,[\"trigger\"]]],[[\"class\"],[\"btn\"]]],[25,\"component\",[\"dropdown-panel/body\"],[[\"content\"],[[19,1,[\"content\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/dropdown-panel.hbs"
    }
  });

  _exports.default = _default;
});