define("nypr-ui/templates/components/site-chrome/nav/links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qcv9LabC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"site-chrome__links-wrapper\"],[7],[0,\"\\n  \"],[6,\"ul\"],[9,\"class\",\"site-chrome__links-list\"],[7],[0,\"\\n    \"],[11,1,[[25,\"hash\",null,[[\"link\"],[[25,\"component\",[\"site-chrome/nav/link\"],null]]]]]],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/site-chrome/nav/links.hbs"
    }
  });

  _exports.default = _default;
});