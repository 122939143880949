define("nypr-ui/templates/components/nypr-story/byline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "13UBfEiF",
    "block": "{\"symbols\":[\"org\",\"index\"],\"statements\":[[6,\"footer\"],[9,\"class\",\"text text--medium text--dotted\"],[9,\"id\",\"byLine\"],[7],[0,\"\\n  \"],[6,\"time\"],[10,\"datetime\",[26,[[25,\"moment-format\",[[20,[\"newsdate\"]],\"YYYY-MM-DD\"],null]]]],[7],[0,\"\\n    \"],[1,[25,\"moment-format\",[[20,[\"newsdate\"]],\"MMM D, YYYY\"],null],false],[0,\"\\n  \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"authors\"]]],null,{\"statements\":[[0,\"    · by \"],[1,[25,\"serial-links\",[[20,[\"authors\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"producingOrganizations\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"byline-producing-orgs\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"separator\"],[7],[0,\"From \"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"producingOrganizations\"]]],null,{\"statements\":[[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"separator\"],[7],[1,[25,\"list-separator\",[[20,[\"producingOrganizations\"]],[19,2,[]]],null],false],[8],[0,\"\\n      \"],[1,[25,\"nypr-story/producing-org\",null,[[\"sourceUrl\",\"org\"],[[20,[\"sourceUrl\"]],[19,1,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[8],[0,\" \\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-story/byline.hbs"
    }
  });

  _exports.default = _default;
});