define("nypr-ui/templates/components/nypr-story/segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5DN/0KpX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"li\"],[9,\"class\",\"list-item list-item--short list-item--dimmed\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"l-split l-split--padsides flush\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"text text--white flush--medium\"],[7],[0,\"\\n      \"],[6,\"a\"],[10,\"href\",[26,[\"/story/\",[20,[\"segment\",\"slug\"]]]]],[9,\"class\",\"link--white\"],[7],[1,[20,[\"segment\",\"title\"]],true],[8],[0,\"\\n      \"],[4,\"if\",[[20,[\"segment\",\"audioAvailable\"]]],null,{\"statements\":[[6,\"span\"],[9,\"class\",\"text--small text--gray\"],[7],[0,\" \"],[1,[20,[\"segment\",\"audioDurationReadable\"]],false],[8]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"l-split flush\"],[7],[0,\"\\n        \"],[11,1,[[25,\"hash\",null,[[\"buttons\",\"message\"],[[25,\"component\",[\"nypr-story/segment/buttons\"],null],[25,\"component\",[\"nypr-story/segment/message\"],null]]]]]],[0,\"\\n      \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[2,\" .l-split \"],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-story/segment.hbs"
    }
  });

  _exports.default = _default;
});