define("nypr-publisher-lib/templates/components/social-iconlist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jg0lla+x",
    "block": "{\"symbols\":[\"link\",\"&default\"],\"statements\":[[4,\"if\",[[22,2]],null,{\"statements\":[[0,\"  \"],[11,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[20,[\"links\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[9,\"class\",\"social-iconlist__item\"],[7],[0,\"\\n      \"],[6,\"a\"],[9,\"class\",\"social-iconlist__link gtm__click-tracking\"],[9,\"data-action\",\"Follow\"],[10,\"data-label\",[26,[\"Follow \",[18,\"followName\"],\" on \",[19,1,[\"title\"]]]]],[10,\"href\",[19,1,[\"href\"]],null],[9,\"target\",\"_blank\"],[10,\"title\",[26,[[18,\"followPrefix\"],\" \",[19,1,[\"title\"]]]]],[7],[1,[25,\"fa-icon\",[[19,1,[\"title\"]]],null],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/social-iconlist.hbs"
    }
  });

  _exports.default = _default;
});