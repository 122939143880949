define("nypr-publisher-lib/templates/components/image-caption", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Njh/nlzX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"image\",\"caption\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,[\"image\",\"caption\"]],true],[6,\"br\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"image\",\"creditsName\"]]],null,{\"statements\":[[0,\"    (\"],[4,\"if\",[[20,[\"image\",\"creditsUrl\"]]],null,{\"statements\":[[0,\"\\n      \"],[6,\"a\"],[10,\"href\",[26,[[20,[\"image\",\"creditsUrl\"]]]]],[7],[1,[20,[\"image\",\"creditsName\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[20,[\"image\",\"creditsName\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[4,\"if\",[[20,[\"image\",\"source\"]]],null,{\"statements\":[[0,\" /\\n\"],[4,\"if\",[[20,[\"image\",\"source\",\"url\"]]],null,{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"href\",[26,[[20,[\"image\",\"source\",\"url\"]]]]],[7],[1,[20,[\"image\",\"source\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[20,[\"image\",\"source\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]},null],[0,\")\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/image-caption.hbs"
    }
  });

  _exports.default = _default;
});