define("nypr-ui/templates/components/nypr-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p8v43M48",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"form\"],[10,\"class\",[18,\"formClass\"],null],[3,\"action\",[[19,0,[]],\"submit\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n\"],[11,1,[[25,\"hash\",null,[[\"status\",\"submit\"],[[25,\"hash\",null,[[\"tried\",\"success\",\"failure\",\"invalid\",\"processing\"],[[20,[\"tried\"]],[20,[\"success\"]],[20,[\"failure\"]],[20,[\"invalid\"]],[20,[\"processing\"]]]]],[25,\"action\",[[19,0,[]],\"submit\"],null]]]]]],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-form.hbs"
    }
  });

  _exports.default = _default;
});