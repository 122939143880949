define("nypr-ui/templates/components/nypr-ui/brand-header/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "enc0dC2W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"left\",\"right\"],[[25,\"component\",[\"nypr-ui/brand-header/header-left\"],[[\"menuItems\"],[[20,[\"menuItems\"]]]]],[25,\"component\",[\"nypr-ui/brand-header/header-right\"],[[\"open\"],[[20,[\"open\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"nypr-ui/brand-header/header-left\",null,[[\"menuItems\"],[[20,[\"menuItems\"]]]]],false],[0,\"\\n  \"],[1,[25,\"nypr-ui/brand-header/header-right\",null,[[\"open\"],[[20,[\"open\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-ui/brand-header/header.hbs"
    }
  });

  _exports.default = _default;
});