define("ember-burger-menu/templates/components/bm-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R4BXdNFr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[25,\"concat\",[[20,[\"containerId\"]],\"-outlet-menu\"],null],[20,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"bm-menu\"],[10,\"style\",[18,\"style\"],null],[7],[0,\"\\n    \"],[11,1,[[25,\"hash\",null,[[\"item\"],[[25,\"component\",[\"bm-menu-item\"],[[\"tagName\",\"menuItems\",\"dismissOnClick\",\"state\"],[[20,[\"itemTagName\"]],[20,[\"menuItems\"]],[20,[\"dismissOnItemClick\"]],[20,[\"state\"]]]]]]]]]],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-burger-menu/templates/components/bm-menu.hbs"
    }
  });

  _exports.default = _default;
});