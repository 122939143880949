define("nypr-ui/templates/components/banner-with-close-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cKH4C6V/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[20,[\"isBannerCookieSet\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"banner-with-close-button__container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"banner-with-close-button__close-button\"],[3,\"action\",[[19,0,[]],\"setBannerCookie\"]],[7],[0,\"\\n      \"],[1,[25,\"nypr-svg\",null,[[\"icon\"],[\"close\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"p\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"elementClicked\"],null],null],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"banner-with-close-button__header-sentence\"],[7],[1,[18,\"headline\"],false],[8],[0,\"\\n\"],[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"        \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/banner-with-close-button.hbs"
    }
  });

  _exports.default = _default;
});