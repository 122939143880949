define("nypr-ui/templates/components/hero/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mAbjTD+U",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"and\",[[25,\"not\",[[20,[\"useBackgroundImage\"]]],null],[25,\"not\",[[20,[\"noHeaderImage\"]]],null],[20,[\"src\"]]],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"hero-image\"],[7],[0,\"\\n    \"],[6,\"img\"],[10,\"src\",[18,\"src\"],null],[10,\"sizes\",[18,\"sizes\"],null],[10,\"srcset\",[18,\"srcset\"],null],[10,\"onload\",[18,\"measure\"],null],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/hero/image.hbs"
    }
  });

  _exports.default = _default;
});