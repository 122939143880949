define("ember-basic-dropdown/templates/components/basic-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AImstd1x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[20,[\"publicAPI\",\"uniqueId\"]],[20,[\"publicAPI\",\"isOpen\"]],[20,[\"publicAPI\",\"disabled\"]],[20,[\"publicAPI\",\"actions\"]],[25,\"component\",[[20,[\"triggerComponent\"]]],[[\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\"],[[25,\"readonly\",[[20,[\"publicAPI\"]]],null],[25,\"readonly\",[[20,[\"hPosition\"]]],null],[25,\"action\",[[19,0,[]],\"handleFocus\"],null],[25,\"readonly\",[[20,[\"renderInPlace\"]]],null],[25,\"readonly\",[[20,[\"vPosition\"]]],null]]]],[25,\"component\",[[20,[\"contentComponent\"]]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\"],[[25,\"readonly\",[[20,[\"publicAPI\"]]],null],[25,\"readonly\",[[20,[\"hPosition\"]]],null],[25,\"readonly\",[[20,[\"renderInPlace\"]]],null],[25,\"readonly\",[[20,[\"preventScroll\"]]],null],[25,\"readonly\",[[20,[\"vPosition\"]]],null],[25,\"readonly\",[[20,[\"destination\"]]],null],[25,\"readonly\",[[20,[\"top\"]]],null],[25,\"readonly\",[[20,[\"left\"]]],null],[25,\"readonly\",[[20,[\"right\"]]],null],[25,\"readonly\",[[20,[\"width\"]]],null],[25,\"readonly\",[[20,[\"height\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown.hbs"
    }
  });

  _exports.default = _default;
});