define("ember-cli-head/templates/components/head-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Crmr51YP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"-in-element\",[[20,[\"headElement\"]]],null,{\"statements\":[[0,\"  \"],[6,\"meta\"],[9,\"name\",\"ember-cli-head-start\"],[9,\"content\",\"\"],[7],[8],[1,[18,\"head-content\"],false],[6,\"meta\"],[9,\"name\",\"ember-cli-head-end\"],[9,\"content\",\"\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-head/templates/components/head-layout.hbs"
    }
  });

  _exports.default = _default;
});