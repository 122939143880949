define("ember-burger-menu/templates/components/bm-outlet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9S9SauHn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"id\",[25,\"concat\",[[20,[\"containerId\"]],\"-outlet-menu\"],null],null],[9,\"class\",\"bm-menu-container\"],[7],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"bm-content\"],[7],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-burger-menu/templates/components/bm-outlet.hbs"
    }
  });

  _exports.default = _default;
});