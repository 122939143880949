define("nypr-ui/templates/components/support-stripe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BGPU0eXx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"support-stripe__wrapper\"],[7],[0,\"\\n  \"],[11,1,[[25,\"hash\",null,[[\"label\",\"text\"],[[25,\"component\",[\"nypr-ui/support-stripe/label\"],null],[25,\"component\",[\"nypr-ui/support-stripe/heading\"],null]]]]]],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"support-stripe__image\"],[10,\"style\",[18,\"image\"],null],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/support-stripe.hbs"
    }
  });

  _exports.default = _default;
});