define("nypr-ui/templates/components/nypr-ui/brand-header/header-logos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQQ6o+WJ",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[25,\"nypr-svg\",null,[[\"icon\",\"className\"],[\"nypr-logo\",\"nypr-brand-header__nypr-logo\"]]],false],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"for-screenreaders\"],[7],[0,\"A list of our sites\"],[8],[0,\"\\n\"],[1,[25,\"nypr-svg\",null,[[\"icon\",\"className\"],[\"left-bracket\",\"nypr-brand-header__square-bracket-open\"]]],false],[0,\"\\n\"],[4,\"each\",[[20,[\"menuItems\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"logo-link\",null,[[\"logo\"],[[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[1,[25,\"nypr-svg\",null,[[\"icon\",\"className\"],[\"right-bracket\",\"nypr-brand-header__square-bracket-close\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-ui/brand-header/header-logos.hbs"
    }
  });

  _exports.default = _default;
});