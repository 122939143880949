define("nypr-audio-services/templates/components/player-notification/autoplay-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aNentrVv",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"notificationMessage\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/player-notification/autoplay-message.hbs"
    }
  });

  _exports.default = _default;
});