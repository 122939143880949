define("nypr-publisher-lib/templates/components/active-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NMtYtJmP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"html\"],[7],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/active-html.hbs"
    }
  });

  _exports.default = _default;
});