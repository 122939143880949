define("nypr-publisher-lib/templates/components/related-stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qf3dADXO",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[20,[\"stories\"]]],null,{\"statements\":[[0,\"  \"],[6,\"h1\"],[9,\"class\",\"related-stories__heading\"],[7],[0,\"Related\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[20,[\"stories\"]]],null,{\"statements\":[[6,\"div\"],[9,\"class\",\"related-item\"],[7],[0,\"\\n  \"],[1,[25,\"story-tease\",null,[[\"item\",\"playContext\"],[[19,1,[]],\"related\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/related-stories.hbs"
    }
  });

  _exports.default = _default;
});