define("nypr-ui/templates/components/logo-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n/qm4/8V",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[10,\"href\",[20,[\"logo\",\"url\"]],null],[9,\"class\",\"gtm__click-tracking\"],[9,\"data-action\",\"Brand Header\"],[10,\"data-label\",[20,[\"logo\",\"svg\"]],null],[9,\"target\",\"_blank\"],[10,\"aria-label\",[26,[\"Visit \",[20,[\"logo\",\"name\"]],\" (Opens in new window)\"]]],[7],[0,\"\\n  \"],[1,[25,\"nypr-svg\",null,[[\"icon\",\"className\"],[[20,[\"logo\",\"svg\"]],[25,\"concat\",[\"logo-link__logo-\",[20,[\"logo\",\"svg\"]]],null]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/logo-link.hbs"
    }
  });

  _exports.default = _default;
});