define('ember-hifi/services/hifi-cache', ['exports', 'ember-hifi/mixins/debug-logging'], function (exports, _debugLogging) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_debugLogging.default, {
    debugName: 'hifi-cache',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_cache', new Map());
    },
    reset: function reset() {
      this.set('_cache', new Map());
    },
    find: function find(urls) {
      urls = Ember.makeArray(urls);
      var cache = this.get('_cache');
      var keysToSearch = Ember.A(urls).map(function (url) {
        return url.url || url;
      });
      var sounds = Ember.A(keysToSearch).map(function (url) {
        return cache.get(url);
      });
      var foundSounds = Ember.A(sounds).compact();

      if (foundSounds.length > 0) {
        this.debug('cache hit for ' + foundSounds[0].get('url'));
      } else {
        this.debug('cache miss for ' + keysToSearch.join(','));
      }

      return foundSounds[0];
    },
    cache: function cache(sound) {
      var cache = this.get("_cache");
      this.debug('caching sound with url: ' + sound.get('url'));
      cache.set(sound.get('url'), sound);
    }
  });
});