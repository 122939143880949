define("nypr-audio-services/templates/components/queue-button/embedded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e97a0svt",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/queue-button/embedded.hbs"
    }
  });

  _exports.default = _default;
});