define("nypr-ui/templates/components/nypr-share-button/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QgnrK/I5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"type\"]],\"Email\"],null]],null,{\"statements\":[[6,\"a\"],[10,\"href\",[25,\"share-url\",[\"Email\",[20,[\"shareUrl\"]],[20,[\"shareText\"]]],null],null],[10,\"class\",[25,\"concat\",[\"gtm__click-tracking \",[20,[\"class\"]]],null],null],[10,\"data-action\",[18,\"data-action\"],null],[10,\"data-label\",[18,\"data-label\"],null],[10,\"data-category\",[18,\"data-category\"],null],[3,\"action\",[[19,0,[]],\"closePopup\"],[[\"preventDefault\"],[false]]],[7],[0,\"\\n\"],[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Email\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,\"button\"],[10,\"class\",[25,\"concat\",[\"gtm__click-tracking \",[20,[\"class\"]]],null],null],[10,\"data-action\",[18,\"data-action\"],null],[10,\"data-label\",[18,\"data-label\"],null],[10,\"data-category\",[18,\"data-category\"],null],[3,\"action\",[[19,0,[]],[20,[\"open\"]],[25,\"share-url\",[[20,[\"type\"]],[20,[\"shareUrl\"]],[20,[\"shareText\"]],[20,[\"via\"]]],null]]],[7],[0,\"\\n\"],[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[18,\"type\"],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-share-button/button.hbs"
    }
  });

  _exports.default = _default;
});