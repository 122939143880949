define("nypr-publisher-lib/templates/components/comments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O7R/z1m6",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[4,\"each\",[[20,[\"comments\"]]],null,{\"statements\":[[0,\"  \"],[6,\"article\"],[10,\"class\",[26,[\"comment-list__item\",[25,\"if\",[[19,1,[\"isStaffComment\"]],\" comment-list__item--highlight\"],null]]]],[10,\"data-id\",[26,[[19,1,[\"id\"]]]]],[7],[0,\"\\n    \"],[6,\"h1\"],[9,\"class\",\"comment-author\"],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"staffNameOverride\"]]],null,{\"statements\":[[0,\"      \"],[1,[19,1,[\"staffNameOverride\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[19,1,[\"location\"]]],null,{\"statements\":[[0,\"      \"],[1,[19,1,[\"author\"]],false],[0,\" from \"],[1,[19,1,[\"location\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[19,1,[\"author\"]],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"comment-body\"],[7],[1,[19,1,[\"comment\"]],true],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"comment-footer\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"comment-date\"],[7],[1,[25,\"moment-format\",[[19,1,[\"publishDate\"]],\"MMM D, YYYY, h:mm A\"],null],false],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"isStaff\"]]],null,{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"href\",[26,[[18,\"adminURL\"],\"/comments/wnyccomment/\",[19,1,[\"id\"]]]]],[9,\"target\",\"_blank\"],[9,\"data-test-selector\",\"moderate\"],[9,\"class\",\"comment-moderate\"],[7],[0,\"Moderate\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n    \"],[2,\" .l-split \"],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/comments-list.hbs"
    }
  });

  _exports.default = _default;
});