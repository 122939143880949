define("nypr-audio-services/templates/components/player-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CiFlpAEo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"p\"],[9,\"class\",\"player-notification-message\"],[7],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"player-notification-action\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"player-notification-dismiss player-notification-close-icon gtm__click-tracking\"],[9,\"data-category\",\"Continuous Play Notification\"],[9,\"data-action\",\"Click to Close Notification\"],[3,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"didDismiss\"]]],null],true]],[7],[0,\"\\n    \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-player-icon\",\"close\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/player-notification.hbs"
    }
  });

  _exports.default = _default;
});