define("nypr-publisher-lib/templates/components/download-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TvgxnJuj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[20,[\"shouldHide\"]]],null,{\"statements\":[[6,\"a\"],[10,\"href\",[20,[\"story\",\"audio\"]],null],[10,\"download\",[20,[\"story\",\"title\"]],null],[9,\"class\",\"download-link\"],[10,\"title\",[26,[\"Download \\\"\",[20,[\"story\",\"title\"]],\"\\\"\"]]],[7],[1,[25,\"nypr-svg\",null,[[\"icon\"],[[20,[\"icon\"]]]]],false],[0,\" \"],[1,[18,\"linkText\"],false],[8]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/download-link.hbs"
    }
  });

  _exports.default = _default;
});