define("nypr-player/templates/components/nypr-player/time-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EckXhgq/",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"timelabel-position\"],[7],[1,[25,\"numeric-duration\",[[20,[\"position\"]]],null],false],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"timelabel-separator\"],[7],[0,\"/\"],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"timelabel-total\"],[7],[1,[25,\"numeric-duration\",[[20,[\"duration\"]]],null],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-player/templates/components/nypr-player/time-label.hbs"
    }
  });

  _exports.default = _default;
});