define("nypr-audio-services/templates/components/listen-button/ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6PXyURBb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[25,\"eq\",[[20,[\"type\"]],\"vanilla\"],null]],null,{\"statements\":[[4,\"unless\",[[20,[\"outerBlock\"]]],null,{\"statements\":[[0,\"    \"],[6,\"label\"],[9,\"class\",\"visuallyhidden\"],[9,\"id\",\"playpause-label\"],[7],[0,\"Play/Pause\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"icon icon--play\",[20,[\"playIcon\"]]]]],false],[0,\"\\n  \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"icon icon--pause\",[20,[\"pauseIcon\"]]]]],false],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"listen-spinner\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/listen-button/ui.hbs"
    }
  });

  _exports.default = _default;
});