define("nypr-ui/templates/components/nypr-share-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7AcWn4w1",
    "block": "{\"symbols\":[\"closePopup\",\"&default\"],\"statements\":[[4,\"nypr-popup-menu\",null,[[\"text\",\"icon\"],[[20,[\"text\"]],\"share\"]],{\"statements\":[[0,\"  \"],[6,\"ul\"],[9,\"class\",\"nypr-sharebutton-list\"],[7],[0,\"\\n    \"],[11,2,[[25,\"hash\",null,[[\"button\"],[[25,\"component\",[\"nypr-share-button/button\"],[[\"openPopup\",\"closePopup\",\"shareUrl\",\"shareText\",\"data-category\",\"data-action\",\"data-label\"],[[25,\"action\",[[19,0,[]],\"popupShareWindow\"],null],[19,1,[]],[20,[\"shareUrl\"]],[20,[\"shareText\"]],[20,[\"data-category\"]],[20,[\"data-action\"]],[20,[\"data-label\"]]]]]]]]]],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-share-button.hbs"
    }
  });

  _exports.default = _default;
});