define("nypr-account-settings/templates/components/nypr-accounts/membership-card/download-tax-link-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KksDdUc0",
    "block": "{\"symbols\":[\"m\",\"&default\"],\"statements\":[[4,\"if\",[[22,2]],null,{\"statements\":[[0,\"  \"],[11,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"nypr-account-modal\",null,[[\"title\",\"closeAction\"],[\"Tax Receipt\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"enableModal\"]]],null],false],null]]],{\"statements\":[[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"id\",\"tax-receipt-modal-body\"],[9,\"class\",\"tax-receipt-modal-body\"],[7],[0,\"\\n\"],[4,\"unless\",[[20,[\"taxReceiptReady\"]]],null,{\"statements\":[[0,\"          \"],[6,\"p\"],[7],[0,\"Generating your \"],[1,[18,\"previousYear\"],false],[0,\" tax receipt link\"],[8],[0,\"\\n          \"],[1,[25,\"animated-loading-icon\",null,[[\"color\",\"width\",\"height\",\"outerScale\",\"innerScale\",\"radius\",\"dotRadius\",\"lineWidth\"],[\"#888\",34,34,12,6,0.0025,2,2]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[6,\"p\"],[7],[0,\"Your \"],[1,[18,\"previousYear\"],false],[0,\" tax receipt is ready to download:\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-accounts/membership-card/download-tax-link-modal.hbs"
    }
  });

  _exports.default = _default;
});