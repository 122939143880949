define("nypr-audio-services/templates/components/listen-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A45Qog0k",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[25,\"listen-button/ui\",null,[[\"type\",\"outerBlock\"],[[20,[\"type\"]],[22,1]]]],false],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/listen-button.hbs"
    }
  });

  _exports.default = _default;
});