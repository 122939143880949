define("nypr-ui/templates/components/nypr-ui/brand-header/header-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gKFXEeGx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"logos\"],[[25,\"component\",[\"nypr-ui/brand-header/header-logos\"],[[\"menuItems\"],[[20,[\"menuItems\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"nypr-ui/brand-header/header-logos\",null,[[\"menuItems\"],[[20,[\"menuItems\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-ui/brand-header/header-left.hbs"
    }
  });

  _exports.default = _default;
});