define("nypr-django-for-ember/templates/components/django-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u98VYu4A",
    "block": "{\"symbols\":[\"ec\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"django-content\"],[7],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"showingOverlay\"]]],null,{\"statements\":[[0,\"  \"],[11,2],[0,\"\\n\"],[4,\"each\",[[20,[\"page\",\"embeddedEmberComponents\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[[19,1,[\"id\"]]]],{\"statements\":[[0,\"      \"],[1,[25,\"component\",[[19,1,[\"componentName\"]]],[[\"classNames\",\"embeddedAttrs\"],[[19,1,[\"componentName\"]],[19,1,[\"args\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"nypr-loading-templates\",null,[[\"type\"],[[20,[\"loadingType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-django-for-ember/templates/components/django-page.hbs"
    }
  });

  _exports.default = _default;
});