define("nypr-player/templates/components/nypr-player/rewind-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e8YUFoRW",
    "block": "{\"symbols\":[],\"statements\":[[6,\"label\"],[9,\"class\",\"u-visuallyhidden\"],[9,\"id\",\"rewind-label\"],[7],[0,\"Rewind 15 seconds\"],[8],[0,\"\\n\"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-player-icon\",\"rewind15\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-player/templates/components/nypr-player/rewind-button.hbs"
    }
  });

  _exports.default = _default;
});