define("nypr-ui/templates/components/site-chrome/nav/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3q/FFbta",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[6,\"li\"],[9,\"class\",\"site-chrome__link\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[20,[\"route\"]]],[[\"tagName\",\"href\",\"class\",\"current-when\"],[\"li\",false,\"site-chrome__link\",[20,[\"currentWhen\"]]]],{\"statements\":[[0,\"    \"],[4,\"link-to\",[[20,[\"route\"]]],null,{\"statements\":[[1,[18,\"text\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/site-chrome/nav/link.hbs"
    }
  });

  _exports.default = _default;
});