define("nypr-ui/templates/components/dropdown-panel/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OTg3bs7/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[20,[\"content\"]]],[[\"class\"],[\"dropdown-body dropdown-fade\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"panel\"],[7],[0,\"\\n    \"],[11,1,[[25,\"hash\",null,[[\"link\"],[[25,\"component\",[\"dropdown-panel/link\"],null]]]]]],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/dropdown-panel/body.hbs"
    }
  });

  _exports.default = _default;
});