define("nypr-audio-services/templates/components/stream-banner/station-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WavDzHeO",
    "block": "{\"symbols\":[],\"statements\":[[1,[20,[\"activeStream\",\"name\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/stream-banner/station-name.hbs"
    }
  });

  _exports.default = _default;
});