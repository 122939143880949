define("liquid-fire/templates/components/liquid-versions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "As8vhR6u",
    "block": "{\"symbols\":[\"version\",\"&default\"],\"statements\":[[4,\"each\",[[20,[\"versions\"]]],null,{\"statements\":[[4,\"if\",[[25,\"lf-or\",[[20,[\"renderWhenFalse\"]],[19,1,[\"value\"]]],null]],null,{\"statements\":[[4,\"liquid-child\",null,[[\"version\",\"liquidChildDidRender\",\"class\"],[[19,1,[]],[25,\"action\",[[19,0,[]],\"childDidRender\"],null],[20,[\"class\"]]]],{\"statements\":[[11,2,[[19,1,[\"value\"]]]]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-versions.hbs"
    }
  });

  _exports.default = _default;
});