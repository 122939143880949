define("nypr-ui/templates/components/site-chrome/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+O7ro02i",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"corner\",\"links\",\"footer\"],[[25,\"component\",[\"site-chrome/nav/corner\"],null],[25,\"component\",[\"site-chrome/nav/links\"],null],[25,\"component\",[\"site-chrome/nav/footer\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/site-chrome/nav.hbs"
    }
  });

  _exports.default = _default;
});