define("nypr-account-settings/templates/components/nypr-account-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C4LPol1k",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"clickOutsideToClose\",\"wrapperClass\",\"containerClass\",\"overlayClass\",\"translucentOverlay\",\"targetAttachment\"],[[20,[\"closeAction\"]],true,\"nypr-account-wrapper\",\"nypr-account-container-outer\",\"nypr-account-overlay\",true,\"center\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"nypr-account-container-inner\"],[7],[0,\"\\n  \"],[11,1,[[25,\"hash\",null,[[\"header\",\"body\",\"footer\"],[[25,\"component\",[\"nypr-account-modal/header\"],[[\"closeAction\"],[[20,[\"closeAction\"]]]]],[25,\"component\",[\"nypr-account-modal/body\"],null],[25,\"component\",[\"nypr-account-modal/footer\"],null]]]]]],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-account-modal.hbs"
    }
  });

  _exports.default = _default;
});