define("nypr-ui/templates/components/nypr-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ySTKzalT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"header\",\"alert\"],[[25,\"component\",[\"nypr-card/header\"],null],[25,\"component\",[\"nypr-card/alert\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"nypr-card/header\",null,[[\"title\",\"buttonAction\"],[[20,[\"title\"]],[20,[\"buttonAction\"]]]]],false],[0,\"\\n\\n  \"],[1,[25,\"component\",[[20,[\"body\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-card.hbs"
    }
  });

  _exports.default = _default;
});