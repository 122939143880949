define("ember-holygrail-layout/components/holygrail-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DxbGIjQa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"left\",\"center\",\"right\"],[[25,\"component\",[\"holygrail-layout.left\"],null],[25,\"component\",[\"holygrail-layout.center\"],null],[25,\"component\",[\"holygrail-layout.right\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-holygrail-layout/components/holygrail-layout/template.hbs"
    }
  });

  _exports.default = _default;
});