define("nypr-account-settings/templates/components/nypr-accounts/resend-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PrnXbPaH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"ready\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"resendAction\"]]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[10,\"class\",[25,\"concat\",[\"resend-button \",[20,[\"classNames\"]]],null],null],[10,\"data-category\",[18,\"data-category\"],null],[10,\"data-action\",[18,\"data-action\"],null],[10,\"data-label\",[18,\"data-label\"],null],[3,\"action\",[[19,0,[]],[25,\"perform\",[[20,[\"tryResend\"]],[20,[\"resendAction\"]]],null]]],[7],[11,1],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"button\"],[10,\"class\",[25,\"concat\",[\"resend-button \",[20,[\"classNames\"]]],null],null],[10,\"data-category\",[18,\"data-category\"],null],[10,\"data-action\",[18,\"data-action\"],null],[10,\"data-label\",[18,\"data-label\"],null],[3,\"action\",[[19,0,[]],\"resend\",[20,[\"target\"]],[20,[\"email\"]]]],[7],[11,1],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"success\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"class\",\"resend-success\"],[7],[1,[18,\"successMessage\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"error\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"class\",\"resend-error\"],[7],[1,[18,\"errorMessage\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-accounts/resend-button.hbs"
    }
  });

  _exports.default = _default;
});