define("nypr-ui/templates/components/nypr-ui/brand-header/menu-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bsav1J4H",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[9,\"class\",\"nypr-brand-header-button mod-network\"],[3,\"action\",[[19,0,[]],[25,\"toggle\",[\"open\",[19,0,[]]],null]]],[7],[0,\"NYPR Network\"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-brand-header-caret\",\"left-arrow\"]]],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-ui/brand-header/menu-button.hbs"
    }
  });

  _exports.default = _default;
});