define("liquid-fire/templates/components/liquid-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uV/bGOj2",
    "block": "{\"symbols\":[\"container\",\"valueVersion\",\"valueVersion\",\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"containerless\"]]],null,{\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[4,\"liquid-versions\",null,[[\"value\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\",\"class\"],[[25,\"if\",[[20,[\"inverted\"]],[25,\"if\",[[20,[\"predicate\"]],false,true],null],[25,\"if\",[[20,[\"predicate\"]],true,false],null]],null],[25,\"hash\",null,[[\"helperName\"],[[20,[\"helperName\"]]]]],[20,[\"use\"]],[20,[\"rules\"]],[22,4],[20,[\"class\"]]]],{\"statements\":[[4,\"if\",[[19,3,[]]],null,{\"statements\":[[0,\"      \"],[11,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[11,4],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-container\",null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[20,[\"containerId\"]],[20,[\"class\"]],[20,[\"growDuration\"]],[20,[\"growPixelsPerSecond\"]],[20,[\"growEasing\"]],[20,[\"shrinkDelay\"]],[20,[\"growDelay\"]],[20,[\"enableGrowth\"]]]],{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\"],[[25,\"if\",[[20,[\"inverted\"]],[25,\"if\",[[20,[\"predicate\"]],false,true],null],[25,\"if\",[[20,[\"predicate\"]],true,false],null]],null],[19,1,[]],[25,\"hash\",null,[[\"helperName\"],[[20,[\"helperName\"]]]]],[20,[\"use\"]],[20,[\"rules\"]],[22,4]]],{\"statements\":[[4,\"if\",[[19,2,[]]],null,{\"statements\":[[0,\"        \"],[11,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[11,4],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-if.hbs"
    }
  });

  _exports.default = _default;
});