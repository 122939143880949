define("nypr-account-settings/templates/components/nypr-account-forms/thanks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q5/L5JdC",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"account-form\"],[7],[0,\"\\n  \"],[6,\"h2\"],[9,\"class\",\"account-form-heading\"],[7],[0,\"Thanks for signing up!\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"We’ve sent an email to \"],[1,[18,\"email\"],false],[0,\" to verify your email address. Usually, the email is sent very quickly, but in rare circumstances it could take a minute or two.\"],[8],[0,\"\\n\\n  \"],[6,\"p\"],[7],[0,\"The link in your email will be valid for 24 hours. Once you click the link in your email, your account will be all set up.\"],[8],[0,\"\\n\\n  \"],[6,\"p\"],[7],[0,\"If you don't see an email after 5 minutes, you can \"],[4,\"nypr-accounts/resend-button\",null,[[\"classNames\",\"data-action\",\"data-label\",\"target\",\"email\"],[\"gtm__click-tracking\",\"Authentication\",\"Resend Verification Email\",[20,[\"resendUrl\"]],[20,[\"email\"]]]],{\"statements\":[[0,\"\\n    Resend the email\\n  \"]],\"parameters\":[]},null],[0,\".\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-account-forms/thanks.hbs"
    }
  });

  _exports.default = _default;
});