define("nypr-player/templates/components/nypr-player/fast-forward-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y0WN3AqJ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"label\"],[9,\"class\",\"u-visuallyhidden\"],[9,\"id\",\"fastforward-label\"],[7],[0,\"Skip ahead 15 seconds\"],[8],[0,\"\\n\"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-player-icon\",\"forward15\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-player/templates/components/nypr-player/fast-forward-button.hbs"
    }
  });

  _exports.default = _default;
});