define("nypr-player/templates/components/nypr-player/backdrop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+YFKhu5K",
    "block": "{\"symbols\":[],\"statements\":[[6,\"svg\"],[7],[0,\"\\n  \"],[6,\"defs\"],[7],[0,\"\\n    \"],[6,\"filter\"],[9,\"id\",\"blur\"],[7],[0,\"\\n      \"],[6,\"feGaussianBlur\"],[9,\"in\",\"SourceGraphic\"],[9,\"stdDeviation\",\"10\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"image\"],[9,\"filter\",\"url(#blur)\"],[9,\"preserveAspectRatio\",\"xMidYMid slice\"],[10,\"xlink:href\",[26,[[18,\"backdropImageUrl\"]]],\"http://www.w3.org/1999/xlink\"],[9,\"x\",\"0\"],[9,\"y\",\"0\"],[9,\"height\",\"100%\"],[9,\"width\",\"100%\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-player/templates/components/nypr-player/backdrop.hbs"
    }
  });

  _exports.default = _default;
});