define("nypr-ui/templates/components/nypr-ui/brand-header/header-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FmdJStbY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"button\"],[[25,\"component\",[\"nypr-ui/brand-header/menu-button\"],[[\"open\"],[[20,[\"open\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"nypr-ui/brand-header/menu-button\",null,[[\"open\"],[[20,[\"open\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-ui/brand-header/header-right.hbs"
    }
  });

  _exports.default = _default;
});