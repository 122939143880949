define("ember-burger-menu/templates/components/burger-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xx1osXeX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"outlet\",\"menu\",\"state\"],[[25,\"component\",[\"bm-outlet\"],[[\"containerId\",\"state\"],[[20,[\"elementId\"]],[20,[\"state\"]]]]],[25,\"component\",[\"bm-menu\"],[[\"containerId\",\"state\"],[[20,[\"elementId\"]],[20,[\"state\"]]]]],[20,[\"state\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-burger-menu/templates/components/burger-menu.hbs"
    }
  });

  _exports.default = _default;
});