define("nypr-publisher-lib/templates/components/tabbed-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+VzPTHu",
    "block": "{\"symbols\":[\"currentIndex\",\"title\",\"index\"],\"statements\":[[6,\"nav\"],[9,\"class\",\"tabbedlist\"],[7],[0,\"\\n  \"],[6,\"ul\"],[9,\"class\",\"tabbedlist-list\"],[7],[0,\"\\n\"],[4,\"liquid-bind\",[[20,[\"activeTabIndex\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"tabTitles\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[10,\"class\",[26,[\"tabbedlist-tab \",[25,\"if\",[[25,\"eq\",[[19,3,[]],[19,1,[]]],null],\"is-active\"],null]]]],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"tabbedlist-button gtm__click-tracking\"],[10,\"data-action\",[26,[\"Switch to \\\"\",[19,2,[]],\"\\\" Tab\"]]],[3,\"action\",[[19,0,[]],\"chooseTab\",[19,3,[]]]],[7],[1,[19,2,[]],false],[8],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[19,3,[]],[19,1,[]]],null]],null,{\"statements\":[[0,\"          \"],[6,\"div\"],[9,\"class\",\"tabbedlist-marker\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"tabbedlist-page\"],[7],[0,\"\\n  \"],[1,[25,\"component\",[[20,[\"activeComponent\"]]],null],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/tabbed-list.hbs"
    }
  });

  _exports.default = _default;
});