define("nypr-ui/templates/components/nypr-social-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Ro6wNCO",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"nypr-social-icons__header\"],[7],[1,[18,\"title\"],false],[8],[0,\"\\n\"],[11,2],[0,\"\\n\"],[6,\"ul\"],[9,\"class\",\"nypr-social-icons__list\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[6,\"li\"],[9,\"class\",\"nypr-social-icons__item\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"nypr-social-icons__link\"],[10,\"title\",[19,1,[\"icon\"]],null],[10,\"href\",[19,1,[\"url\"]],null],[9,\"target\",\"_blank\"],[7],[0,\"\\n      \"],[6,\"i\"],[10,\"class\",[26,[\"nypr-social-icons__icon fa fa-\",[19,1,[\"icon\"]]]]],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-social-icons.hbs"
    }
  });

  _exports.default = _default;
});