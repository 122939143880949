define("nypr-audio-services/templates/components/stream-banner/controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bbgBlw7J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"listen-button\",null,[[\"class\",\"type\",\"itemPK\",\"itemTitle\"],[\"stream-banner-listenbutton nudge\",\"blue-boss\",[20,[\"activeStream\",\"slug\"]],[20,[\"activeStream\",\"name\"]]]],{\"statements\":[[0,\"    Listen Live\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/stream-banner/controls.hbs"
    }
  });

  _exports.default = _default;
});