define("nypr-ui/templates/components/dropdown-panel/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QSES/wF6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"href\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"class\",[26,[\"panel-link text--medium link--dark \",[18,\"classNames\"]]]],[10,\"href\",[18,\"href\"],null],[10,\"data-category\",[18,\"data-category\"],null],[10,\"data-action\",[18,\"data-action\"],null],[10,\"data-label\",[18,\"data-label\"],null],[10,\"data-value\",[18,\"data-value\"],null],[9,\"target\",\"_blank\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/dropdown-panel/link.hbs"
    }
  });

  _exports.default = _default;
});