define('ember-cookies/utils/serialize-cookie', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var serializeCookie = exports.serializeCookie = function serializeCookie(name, value) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var cookie = name + '=' + value;

    if (!Ember.isEmpty(options.domain)) {
      cookie = cookie + '; domain=' + options.domain;
    }
    if (Ember.typeOf(options.expires) === 'date') {
      cookie = cookie + '; expires=' + options.expires.toUTCString();
    }
    if (!Ember.isEmpty(options.maxAge)) {
      cookie = cookie + '; max-age=' + options.maxAge;
    }
    if (options.secure) {
      cookie = cookie + '; secure';
    }
    if (options.httpOnly) {
      cookie = cookie + '; httpOnly';
    }
    if (!Ember.isEmpty(options.path)) {
      cookie = cookie + '; path=' + options.path;
    }
    if (!Ember.isEmpty(options.sameSite)) {
      cookie = cookie + '; SameSite=' + options.sameSite;
    }

    return cookie;
  };
});