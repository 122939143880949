define("nypr-publisher-lib/templates/components/appearance-credit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R2JgT+ay",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"appearances\"]]],null,{\"statements\":[[6,\"div\"],[9,\"class\",\"appearance-credit\"],[7],[0,\"\\n  \"],[1,[18,\"creditText\"],false],[0,\" \"],[1,[25,\"serial-links\",[[20,[\"appearances\"]]],null],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/appearance-credit.hbs"
    }
  });

  _exports.default = _default;
});