define("nypr-publisher-lib/templates/components/social-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Zp8m5Lp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"facebook\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"href\",[20,[\"facebook\",\"href\"]],null],[9,\"target\",\"_blank\"],[9,\"class\",\"social-links__btn\"],[7],[0,\"\\n    \"],[1,[25,\"fa-icon\",[\"facebook\"],null],false],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"social-links__hidden\"],[7],[0,\"Share on Facebook\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"twitter\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"href\",[20,[\"twitter\",\"href\"]],null],[9,\"target\",\"_blank\"],[9,\"class\",\"social-links__btn\"],[7],[0,\"\\n    \"],[1,[25,\"fa-icon\",[\"twitter\"],null],false],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"social-links__hidden\"],[7],[0,\"Share on Twitter\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"newsletter\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[9,\"class\",\"social-links__btn--newsletter\"],[10,\"href\",[20,[\"newsletter\",\"href\"]],null],[9,\"target\",\"_blank\"],[7],[0,\"Sign up for our newsletter\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/social-links.hbs"
    }
  });

  _exports.default = _default;
});