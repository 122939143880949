define("nypr-ui/templates/components/nypr-mini-chrome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xVSs7Cvc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"nypr-mini-chrome__top\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"href\",\"/\"],[9,\"class\",\"nypr-mini-chrome__left\"],[7],[1,[25,\"nypr-svg\",null,[[\"icon\",\"className\"],[\"wnyc-logo\",\"wnyc-logo\"]]],false],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"nypr-mini-chrome__right\"],[7],[1,[18,\"promo\"],true],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-mini-chrome.hbs"
    }
  });

  _exports.default = _default;
});