define("nypr-player/templates/components/nypr-player/volume-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SJH0Vd6n",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"nypr-player-volume-control\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-volume-slider-wrapper\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"js-volume-slider nypr-player-volume-slider\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"nypr-player-volume-slider-track\"],[10,\"style\",[18,\"trackWidth\"],null],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"js-volume-slider-handle nypr-player-volume-slider-handle\"],[10,\"style\",[18,\"handlePosition\"],null],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"nypr-player-button mod-volume\"],[3,\"action\",[[19,0,[]],\"toggleMute\"]],[7],[0,\"\\n    \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-player-icon mod-volume-unmuted\",\"volume\"]]],false],[0,\"\\n    \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-player-icon mod-volume-muted\",\"mute\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-player/templates/components/nypr-player/volume-control.hbs"
    }
  });

  _exports.default = _default;
});