define("nypr-publisher-lib/templates/components/x-marquee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QnxbPakx",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"marquee-image\"],[10,\"style\",[18,\"marqueeImageCSS\"],null],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"marquee-gradient\"],[10,\"style\",[18,\"marqueeGradientCSS\"],null],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"marquee-footer\"],[7],[0,\"\\n  \"],[1,[25,\"channel-header\",null,[[\"listenLive\",\"model\",\"isStaff\",\"adminURL\"],[[20,[\"listenLive\"]],[20,[\"model\"]],[20,[\"isStaff\"]],[20,[\"adminURL\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/x-marquee.hbs"
    }
  });

  _exports.default = _default;
});