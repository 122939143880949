define("nypr-audio-services/templates/components/clear-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0mz2pzmS",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"clearhistory-main\"],[7],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"clearhistory-button\"],[3,\"action\",[[19,0,[]],\"showConfirmation\"]],[7],[0,\"Clear my listening history\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"clearhistory-confirmation\"],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"clearhistory-prompt\"],[7],[0,\"Are you sure?\"],[8],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"clearhistory-button\"],[3,\"action\",[[19,0,[]],\"clearHistory\"]],[7],[0,\"Yes\"],[8],[0,\"\\n  /\\n  \"],[6,\"button\"],[9,\"class\",\"clearhistory-button\"],[3,\"action\",[[19,0,[]],\"cancel\"]],[7],[0,\"No\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/clear-history.hbs"
    }
  });

  _exports.default = _default;
});