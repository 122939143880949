define("nypr-account-settings/templates/components/nypr-accounts/membership-card/donation-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DlsgGvYD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"orderKey\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"class\",[26,[\"pledge-donate-button \",[18,\"siteDomain\"],\"-pledge-donate-button gtm__click-tracking\"]]],[9,\"data-action\",\"Member Center\"],[9,\"data-label\",\"Donate\"],[10,\"href\",[26,[\"https://\",[18,\"pledgePrefix\"],\".\",[18,\"siteDomain\"],\".org/donate/membership-sustainer/sustainer/?order_id=\",[18,\"orderKey\"]]]],[9,\"target\",\"_blank\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"class\",[26,[\"pledge-donate-button \",[18,\"siteDomain\"],\"-pledge-donate-button gtm__click-tracking\"]]],[9,\"data-action\",\"Member Center\"],[9,\"data-label\",\"Donate\"],[10,\"href\",[26,[\"https://\",[18,\"pledgePrefix\"],\".\",[18,\"siteDomain\"],\".org/donate/mc-main/\"]]],[9,\"target\",\"_blank\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-accounts/membership-card/donation-button.hbs"
    }
  });

  _exports.default = _default;
});