define("nypr-publisher-lib/templates/components/social-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+UCY96PU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"isTwitter\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"href\",[26,[\"https://twitter.com/\",[20,[\"item\",\"contact-string\"]]]]],[9,\"data-size\",\"large\"],[9,\"class\",\"twitter-follow-button\"],[9,\"data-show-count\",\"false\"],[7],[0,\"Follow @\"],[1,[20,[\"item\",\"contact-string\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"a\"],[9,\"class\",\"btn btn--circle btn--blacktext\"],[10,\"href\",[18,\"url\"],null],[9,\"target\",\"_blank\"],[7],[0,\"\\n    \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[18,\"iconname\"]]]],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/social-button.hbs"
    }
  });

  _exports.default = _default;
});