define("nypr-ui/templates/components/nypr-story/body-transcript", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBwUPaJR",
    "block": "{\"symbols\":[\"tabs\",\"tablist\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"transcript\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"nypr-tabs\",null,[[\"selection\",\"class\"],[[20,[\"selection\"]],\"article-tabs\"]],{\"statements\":[[4,\"component\",[[19,1,[\"tablist\"]]],null,{\"statements\":[[0,\"      \"],[4,\"component\",[[19,2,[\"tab\"]],\"summary\"],[[\"on-select\"],[[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"selection\"]]],null]],null]]],{\"statements\":[[0,\"Summary\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"component\",[[19,2,[\"tab\"]],\"transcript\"],[[\"on-select\"],[[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"selection\"]]],null]],null]]],{\"statements\":[[0,\"Transcript\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"component\",[[19,1,[\"tabpanel\"]],\"summary\"],null,{\"statements\":[[0,\"      \"],[11,3],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[19,1,[\"tabpanel\"]],\"transcript\"],null,{\"statements\":[[0,\"    \"],[6,\"section\"],[9,\"class\",\"transcript\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"for-print\"],[9,\"aria-hidden\",\"true\"],[7],[0,\"Transcript\"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"text\"],[7],[0,\"\\n          \"],[1,[18,\"transcript\"],true],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[11,3],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-story/body-transcript.hbs"
    }
  });

  _exports.default = _default;
});