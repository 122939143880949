define("nypr-ui/templates/components/nypr-brick-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFgjUbyR",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[20,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"nypr-brick-item\",null,[[\"item\",\"template\"],[[19,1,[]],\"vertical\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-brick-grid.hbs"
    }
  });

  _exports.default = _default;
});