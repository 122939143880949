define("nypr-ui/templates/components/nypr-popup-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DC2RuWP6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"click-outside\",null,[[\"action\"],[[25,\"action\",[[19,0,[]],\"closePopup\"],null]]],{\"statements\":[[6,\"button\"],[9,\"class\",\"nypr-popupmenu-button\"],[3,\"action\",[[19,0,[]],\"togglePopup\"]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"icon\",[20,[\"icon\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"text\"],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"nypr-popupmenu-popup\"],[10,\"style\",[18,\"popupStyle\"],null],[7],[0,\"\\n  \"],[11,1,[[25,\"action\",[[19,0,[]],\"closePopup\"],null]]],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-popupmenu-popup-pointer\"],[10,\"style\",[18,\"pointerStyle\"],null],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-popup-menu.hbs"
    }
  });

  _exports.default = _default;
});