define("nypr-audio-services/templates/components/nypr-player-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WcztAbsK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"currentTitle\",\"showTitle\",\"showUrl\",\"storyTitle\",\"storyUrl\",\"songDetails\",\"currentSound\",\"currentAudio\",\"currentAudioType\",\"currentAudioId\",\"story\",\"isStream\",\"streamScheduleUrl\",\"streamPlaylistUrl\",\"streamUrl\",\"streamName\",\"autofocus\",\"backdropImageUrl\",\"onPlay\",\"onPause\",\"onFastForward\",\"onRewind\",\"onSetPosition\",\"revealNotificationBar\",\"notification\"],[[20,[\"currentTitle\"]],[20,[\"showTitle\"]],[20,[\"showUrl\"]],[20,[\"storyTitle\"]],[20,[\"storyUrl\"]],[20,[\"songDetails\"]],[20,[\"currentSound\"]],[20,[\"currentAudio\"]],[20,[\"currentAudioType\"]],[20,[\"currentAudioId\"]],[20,[\"story\"]],[20,[\"isStream\"]],[20,[\"streamScheduleUrl\"]],[20,[\"streamPlaylistUrl\"]],[20,[\"streamUrl\"]],[20,[\"streamName\"]],[20,[\"autofocus\"]],[20,[\"backdropImageUrl\"]],[25,\"action\",[[19,0,[]],\"onPlay\"],null],[25,\"action\",[[19,0,[]],\"onPause\"],null],[25,\"action\",[[19,0,[]],\"onFastForward\"],null],[25,\"action\",[[19,0,[]],\"onRewind\"],null],[25,\"action\",[[19,0,[]],\"onSetPosition\"],null],[20,[\"revealNotificationBar\"]],[25,\"component\",[\"player-notification\"],[[\"didDismiss\"],[[20,[\"didDismiss\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/nypr-player-integration.hbs"
    }
  });

  _exports.default = _default;
});