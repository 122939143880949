define("nypr-ui/templates/components/animated-loading-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "irAVWBSl",
    "block": "{\"symbols\":[],\"statements\":[[6,\"canvas\"],[9,\"id\",\"loading-anim\"],[9,\"class\",\"icon-canvas\"],[10,\"width\",[26,[[18,\"width\"]]]],[10,\"height\",[26,[[18,\"height\"]]]],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/animated-loading-icon.hbs"
    }
  });

  _exports.default = _default;
});