define("nypr-ads/templates/components/dfp-ad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tCpvLrq0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"id\",[18,\"target\"],null],[10,\"class\",[18,\"slotClassNames\"],null],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ads/templates/components/dfp-ad.hbs"
    }
  });

  _exports.default = _default;
});