define("nypr-account-settings/templates/components/nypr-accounts/social-connect-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p1TtJQUI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"connected\"]]],null,{\"statements\":[[0,\"\\n  \"],[6,\"a\"],[9,\"class\",\"nypr-social-connect__link gtm__click-tracking\"],[9,\"data-action\",\"Personal Info\"],[9,\"data-label\",\"Manage Facebook Connection\"],[10,\"href\",[18,\"manageUrl\"],null],[9,\"target\",\"_blank\"],[9,\"rel\",\"noopener noreferrer\"],[7],[0,\"Manage \"],[1,[18,\"providerName\"],false],[0,\" connection\"],[8],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[6,\"button\"],[10,\"class\",[26,[\"nypr-social-connect__button \",[18,\"buttonClass\"]]]],[10,\"data-test-selector\",[26,[\"social-connect-\",[18,\"providerName\"]]]],[3,\"action\",[[19,0,[]],[20,[\"buttonAction\"]]]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"providerIcon\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",[26,[\"nypr-social-connect__icon fa fa-\",[18,\"providerIcon\"]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    Connect with \"],[1,[18,\"providerName\"],false],[0,\"\\n  \"],[8],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-accounts/social-connect-button.hbs"
    }
  });

  _exports.default = _default;
});