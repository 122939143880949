define("nypr-audio-services/templates/components/queue-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VRQMYxWo",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"transition-plus-to-check\"],[10,\"data-state\",[25,\"if\",[[20,[\"inQueue\"]],\"is-check\"],null],null],[7],[6,\"span\"],[9,\"class\",\"left\"],[7],[8],[6,\"span\"],[9,\"class\",\"right\"],[7],[8],[8],[0,\"\\n\"],[4,\"unless\",[[25,\"eq\",[[20,[\"type\"]],\"small-blue\"],null]],null,{\"statements\":[[6,\"span\"],[7],[1,[25,\"if\",[[20,[\"inQueue\"]],\"Queued\",\"Queue\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/queue-button.hbs"
    }
  });

  _exports.default = _default;
});