define("nypr-player/templates/components/nypr-player/progress-meter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/kACSjdM",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"nypr-player-progress-wrapper\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-progress-bg js-nypr-player-progress-bg\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-progress-downloaded\"],[10,\"style\",[18,\"downloadedPercentage\"],null],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-progress-played\"],[10,\"style\",[18,\"playedPercentage\"],null],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-progress-playhead js-nypr-player-progress-playhead\"],[10,\"style\",[18,\"playheadPosition\"],null],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-player/templates/components/nypr-player/progress-meter.hbs"
    }
  });

  _exports.default = _default;
});