define("nypr-ui/templates/components/nypr-floating-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aO29MddS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[20,[\"hideBanner\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",[26,[\"nypr-floating-banner__wrapper\",[25,\"if\",[[20,[\"arrow\"]],\" nypr-floating-banner__with-arrow\"],null]]]],[7],[0,\"\\n\"],[4,\"nypr-card\",null,[[\"class\"],[\"nypr-floating-banner\"]],{\"statements\":[[0,\"      \\n      \"],[11,1],[0,\"\\n    \\n      \"],[6,\"button\"],[9,\"class\",\"nypr-floating-banner__close\"],[3,\"action\",[[19,0,[]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"hideBanner\"]]],null],true],null]]],[7],[0,\"\\n        \"],[1,[25,\"nypr-svg\",null,[[\"icon\"],[\"close\"]]],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-floating-banner.hbs"
    }
  });

  _exports.default = _default;
});