define("ivy-tabs/templates/components/ivy-tabs-tablist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W5T7rFyM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"tab\"],[[25,\"component\",[\"ivy-tabs-tab\"],[[\"tabList\"],[[19,0,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ivy-tabs/templates/components/ivy-tabs-tablist.hbs"
    }
  });

  _exports.default = _default;
});