define("nypr-audio-services/templates/components/stream-banner/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2B/WaAeK",
    "block": "{\"symbols\":[\"stream\",\"&default\"],\"statements\":[[4,\"power-select\",null,[[\"selectedItemComponent\",\"triggerComponent\",\"renderInPlace\",\"searchEnabled\",\"dropdownClass\",\"selected\",\"options\",\"onchange\"],[[20,[\"selectedItemComponent\"]],\"stream-banner/trigger\",true,false,\"stream-banner-dropdown\",[20,[\"activeStream\"]],[20,[\"streams\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"activeStream\"]]],null]],null]]],{\"statements\":[[0,\"  \"],[11,2,[[19,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/stream-banner/dropdown.hbs"
    }
  });

  _exports.default = _default;
});