define("nypr-publisher-lib/templates/components/story-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/iW4Qy0T",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,\"ul\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"teaseList\"]]],null,{\"statements\":[[0,\"  \"],[6,\"li\"],[9,\"class\",\"story-list-item\"],[7],[0,\"\\n    \"],[1,[25,\"story-tease\",null,[[\"flipped\",\"parentTitle\",\"isStaff\",\"adminURL\",\"item\",\"playContext\"],[[20,[\"flipTease\"]],[20,[\"parentTitle\"]],[20,[\"session\",\"data\",\"isStaff\"]],[20,[\"adminURL\"]],[19,1,[]],[20,[\"playContext\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/story-list.hbs"
    }
  });

  _exports.default = _default;
});