define("nypr-ui/templates/components/hero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IuUN5n5p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",[25,\"concat\",[\"image-container\",[25,\"if\",[[20,[\"gradient\"]],\" with-gradient\"],null],[25,\"if\",[[20,[\"no-image\"]],\" no-image\"],null]],null],null],[10,\"style\",[18,\"backgroundImage\"],null],[7],[0,\"\\n    \"],[11,1,[[25,\"hash\",null,[[\"image\",\"top\",\"headline\",\"blurb\",\"footer\"],[[25,\"component\",[\"nypr-ui/hero/image\"],[[\"sendImage\",\"useBackgroundImage\",\"noHeaderImage\",\"measure\"],[[25,\"action\",[[19,0,[]],[20,[\"setImage\"]]],null],[20,[\"useBackgroundImage\"]],[20,[\"noHeaderImage\"]],[25,\"action\",[[19,0,[]],[20,[\"measure\"]]],null]]]],[25,\"component\",[\"nypr-ui/hero/top\"],null],[25,\"component\",[\"nypr-ui/hero/headline\"],null],[25,\"component\",[\"nypr-ui/hero/blurb\"],null],[25,\"component\",[\"nypr-ui/hero/footer\"],null]]]]]],[0,\"\\n  \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"showCredits\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"hero-source\"],[7],[0,\"\\n      \"],[1,[18,\"caption\"],false],[4,\"if\",[[25,\"or\",[[20,[\"credit\"]],[20,[\"source\"]]],null]],null,{\"statements\":[[0,\" (\"],[1,[18,\"credit\"],false],[1,[25,\"if\",[[25,\"and\",[[20,[\"credit\"]],[20,[\"source\"]]],null],\" / \"],null],false],[1,[25,\"link-or-text\",[[20,[\"source\"]]],null],true],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"log\",[\"Usage without a block is undefined\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/hero.hbs"
    }
  });

  _exports.default = _default;
});