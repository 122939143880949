define("nypr-publisher-lib/templates/components/playlist-credit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D/H2/Fel",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"amazonUrl\"]]],null,{\"statements\":[[0,\"  \\\"\"],[6,\"a\"],[10,\"href\",[18,\"amazonUrl\"],null],[9,\"target\",\"_blank\"],[10,\"title\",[26,[\"Buy \\\"\",[18,\"unquotedTitle\"],\"\\\" on Amazon\"]]],[7],[1,[18,\"unquotedTitle\"],false],[8],[0,\"\\\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \\\"\"],[1,[18,\"unquotedTitle\"],false],[0,\"\\\"\\n\"]],\"parameters\":[]}],[1,[25,\"if\",[[20,[\"musicEntry\",\"artists\"]],[25,\"concat\",[\"by \",[20,[\"musicEntry\",\"artists\"]]],null]],null],false],[0,\"\\n\"],[1,[25,\"if\",[[20,[\"musicEntry\",\"label\"]],[25,\"concat\",[\"from \",[20,[\"musicEntry\",\"label\"]]],null]],null],false],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/playlist-credit.hbs"
    }
  });

  _exports.default = _default;
});