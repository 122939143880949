define('ember-scroll-to/services/scroller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DURATION = 750;
  var EASING = 'swing';
  var OFFSET = 0;

  var RSVP = Ember.RSVP;
  exports.default = Ember.Service.extend({

    // ----- Static properties -----
    duration: DURATION,
    easing: EASING,
    offset: OFFSET,

    // ----- Computed properties -----
    scrollable: Ember.computed(function () {
      return Ember.$('html, body');
    }),

    // ----- Methods -----
    getJQueryElement: function getJQueryElement(target) {
      var jQueryElement = Ember.$(target);

      if (!jQueryElement) {
        Ember.Logger.warn("element couldn't be found:", target);
        return;
      }

      return jQueryElement;
    },
    getVerticalCoord: function getVerticalCoord(target) {
      var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

      var jQueryElement = this.getJQueryElement(target);
      return jQueryElement.offset().top + offset;
    },
    scrollVertical: function scrollVertical(target) {
      var _this = this;

      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return new RSVP.Promise(function (resolve, reject) {
        _this.get('scrollable').animate({
          scrollTop: _this.get('scrollable').scrollTop() - _this.get('scrollable').offset().top + _this.getVerticalCoord(target, opts.offset)
        }, opts.duration || _this.get('duration'), opts.easing || _this.get('easing'), opts.complete).promise().then(resolve, reject);
      });
    }
  });
});