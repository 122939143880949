define("nypr-publisher-lib/templates/components/embed-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ol4eLDBG",
    "block": "{\"symbols\":[],\"statements\":[[6,\"button\"],[9,\"class\",\"embed-button__button\"],[3,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"showEmbed\"]]],null],true]],[7],[1,[25,\"nypr-svg\",null,[[\"icon\"],[[20,[\"icon\"]]]]],false],[0,\" \"],[1,[18,\"buttonText\"],false],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"showEmbed\"]]],null,{\"statements\":[[4,\"click-outside\",null,[[\"class\",\"action\"],[\"embed-button__embed-popup\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"showEmbed\"]]],null],false],null]]],{\"statements\":[[0,\"    \"],[6,\"h2\"],[9,\"class\",\"embed-button__embed-header\"],[7],[0,\"Want to embed this player?\"],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"embed-button__embed-instructions\"],[7],[0,\"Use the code below.\"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"embed-button__embed-code\"],[7],[1,[20,[\"story\",\"embedCode\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/embed-button.hbs"
    }
  });

  _exports.default = _default;
});