define("nypr-account-settings/templates/components/nypr-accounts/membership-card/help-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tcazu1/c",
    "block": "{\"symbols\":[\"m\",\"header\",\"&default\"],\"statements\":[[4,\"if\",[[22,3]],null,{\"statements\":[[0,\"  \"],[11,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"nypr-account-modal\",null,[[\"closeAction\"],[[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"enableModal\"]]],null],false],null]]],{\"statements\":[[4,\"component\",[[19,1,[\"header\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"component\",[[19,2,[\"title\"]],\"Membership Help\"],null],false],[0,\"\\n\"],[4,\"component\",[[19,2,[\"button\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"nypr-svg\",null,[[\"icon\"],[\"close\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[7],[0,\"\\n      If you've donated but don't see your donation info here, it may be because you've donated with a different email. You can change your donation email in your Personal Info section.\\n    \"],[8],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"\\n      Other questions: \"],[6,\"a\"],[9,\"href\",\"/support/faq/\"],[7],[0,\"Check out our FAQs\"],[8],[6,\"br\"],[7],[8],[0,\"\\n      Or contact us: \"],[6,\"a\"],[9,\"href\",\"/contact\"],[9,\"target\",\"_blank\"],[7],[1,[18,\"siteDomain\"],false],[0,\".org/contact\"],[8],[0,\" or 646-829-4000\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[19,1,[\"footer\"]]],null,{\"statements\":[[0,\"      \"],[6,\"button\"],[9,\"class\",\"nypr-account-confirm\"],[9,\"data-test-selector\",\"confirm-disable\"],[3,\"action\",[[19,0,[]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"enableModal\"]]],null],false],null]]],[7],[0,\"Got it!\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-accounts/membership-card/help-modal.hbs"
    }
  });

  _exports.default = _default;
});