define("ember-cli-flash/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jhDkEuED",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"  \"],[11,1,[[19,0,[]],[20,[\"flash\"]],[25,\"action\",[[19,0,[]],\"close\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[20,[\"flash\",\"message\"]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"showProgressBar\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"alert-progress\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"alert-progressBar\"],[10,\"style\",[18,\"progressDuration\"],null],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-flash/templates/components/flash-message.hbs"
    }
  });

  _exports.default = _default;
});