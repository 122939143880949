define("nypr-publisher-lib/templates/components/nav-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DJ4+feN5",
    "block": "{\"symbols\":[\"currentIndex\",\"link\",\"index\"],\"statements\":[[6,\"h1\"],[7],[0,\"Sub Nav\"],[8],[0,\"\\n\"],[4,\"liquid-bind\",[[20,[\"activeTabIndex\"]]],null,{\"statements\":[[6,\"ul\"],[9,\"class\",\"nav-links__list\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"parsedLinks\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[10,\"class\",[26,[[25,\"if\",[[25,\"eq\",[[19,3,[]],[19,1,[]]],null],\"is-active \"],null],\"nav-links__list-item\"]]],[3,\"action\",[[19,0,[]],\"moveBar\",[19,3,[]],[19,2,[\"nav-slug\"]]],[[\"preventDefault\"],[false]]],[7],[0,\"\\n\"],[4,\"if\",[[25,\"and\",[[19,2,[\"nav-slug\"]],[20,[\"channelSlug\"]]],null]],null,{\"statements\":[[0,\"        \"],[4,\"link-to\",[[25,\"concat\",[[20,[\"navRoot\"]],\".page\"],null],[20,[\"channelSlug\"]],[19,2,[\"nav-slug\"]]],[[\"classNames\"],[\"nav-links__link\"]],{\"statements\":[[1,[19,2,[\"title\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[19,2,[\"nav-slug\"]]],null,{\"statements\":[[0,\"        \"],[4,\"link-to\",[[25,\"concat\",[[20,[\"navRoot\"]],\".page\"],null],[19,2,[\"nav-slug\"]]],[[\"classNames\"],[\"nav-links__link\"]],{\"statements\":[[1,[19,2,[\"title\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"href\",[19,2,[\"href\"]],null],[9,\"class\",\"nav-links__link\"],[9,\"target\",\"_blank\"],[7],[1,[19,2,[\"title\"]],false],[8],[0,\"\\n      \"]],\"parameters\":[]}]],\"parameters\":[]}],[4,\"if\",[[25,\"eq\",[[19,3,[]],[19,1,[]]],null]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"nav-bar\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[2,3]},null],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/nav-links.hbs"
    }
  });

  _exports.default = _default;
});