define("nypr-player/templates/components/nypr-player/listen-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CgG4jyF7",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"listen-button-content\"],[7],[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"u-visuallyhidden\"],[9,\"id\",\"playpause-label\"],[7],[0,\"Play/Pause\"],[8],[0,\"\\n  \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-player-icon mod-play\",\"play-hollow\"]]],false],[0,\"\\n  \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-player-icon mod-pause\",\"pause-hollow\"]]],false],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"listen-spinner\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-player/templates/components/nypr-player/listen-button.hbs"
    }
  });

  _exports.default = _default;
});