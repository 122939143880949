define("nypr-audio-services/templates/components/stream-banner/station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e6jj1qZP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"stationTitle\",\"dropdown\"],[[25,\"component\",[\"stream-banner/station-name\"],[[\"activeStream\"],[[20,[\"activeStream\"]]]]],[25,\"component\",[\"stream-banner/dropdown\"],[[\"activeStream\",\"streams\"],[[20,[\"activeStream\"]],[20,[\"streams\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/stream-banner/station.hbs"
    }
  });

  _exports.default = _default;
});