define("nypr-ui/templates/components/nypr-story/producing-org", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O5X3alpj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"url\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"href\",[26,[[18,\"url\"]]]],[9,\"class\",\"link--dark\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"and\",[[20,[\"org\",\"logo\"]],[20,[\"org\",\"logo\",\"template\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"img\"],[9,\"class\",\"img--inline\"],[10,\"alt\",[26,[[20,[\"org\",\"name\"]],\" (Opens in new window)\"]]],[10,\"src\",[26,[[25,\"image-template\",[[20,[\"org\",\"logo\",\"template\"]],0,15,[20,[\"org\",\"logo\",\"crop\"]],99],null]]]],[10,\"srcset\",[26,[[25,\"image-template\",[[20,[\"org\",\"logo\",\"template\"]],0,30,[20,[\"org\",\"logo\",\"crop\"]],99],null],\" 2x,\\n            \",[25,\"image-template\",[[20,[\"org\",\"logo\",\"template\"]],0,45,[20,[\"org\",\"logo\",\"crop\"]],99],null],\" 3x\"]]],[9,\"height\",\"15\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[7],[1,[20,[\"org\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"org\",\"logo\"]],[20,[\"org\",\"logo\",\"template\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"img\"],[9,\"class\",\"img--inline\"],[10,\"alt\",[26,[[20,[\"org\",\"name\"]]]]],[10,\"src\",[26,[[25,\"image-template\",[[20,[\"org\",\"logo\",\"template\"]],0,15,[20,[\"org\",\"logo\",\"crop\"]],99],null]]]],[10,\"srcset\",[26,[[25,\"image-template\",[[20,[\"org\",\"logo\",\"template\"]],0,30,[20,[\"org\",\"logo\",\"crop\"]],99],null],\" 2x,\\n            \",[25,\"image-template\",[[20,[\"org\",\"logo\",\"template\"]],0,45,[20,[\"org\",\"logo\",\"crop\"]],99],null],\" 3x\"]]],[9,\"height\",\"15\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[7],[1,[20,[\"org\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-story/producing-org.hbs"
    }
  });

  _exports.default = _default;
});