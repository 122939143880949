define("ember-basic-dropdown/templates/components/basic-dropdown/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/csNahK6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"dropdown\",\"isOpen\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\",\"class\"],[[20,[\"to\"]],[20,[\"renderInPlace\"]],\"ember-basic-dropdown-content-wormhole-origin\"]],{\"statements\":[[4,\"if\",[[20,[\"overlay\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"ember-basic-dropdown-overlay\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"basic-dropdown/content-element\",null,[[\"tagName\",\"id\",\"class\",\"style\",\"dir\"],[[20,[\"_contentTagName\"]],[20,[\"dropdownId\"]],[25,\"concat\",[\"ember-basic-dropdown-content \",[20,[\"class\"]],\" \",[20,[\"defaultClass\"]],\" \",[25,\"if\",[[20,[\"renderInPlace\"]],\"ember-basic-dropdown-content--in-place \"],null],[25,\"if\",[[20,[\"hPosition\"]],[25,\"concat\",[\"ember-basic-dropdown-content--\",[20,[\"hPosition\"]]],null]],null],\" \",[25,\"if\",[[20,[\"vPosition\"]],[25,\"concat\",[\"ember-basic-dropdown-content--\",[20,[\"vPosition\"]]],null]],null],\" \",[20,[\"animationClass\"]]],null],[20,[\"style\"]],[20,[\"dir\"]]]],{\"statements\":[[0,\"      \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"id\",[18,\"dropdownId\"],null],[9,\"class\",\"ember-basic-dropdown-content-placeholder\"],[9,\"style\",\"display: none;\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
    }
  });

  _exports.default = _default;
});