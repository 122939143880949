define("nypr-audio-services/templates/components/stream-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MSf5sMN2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"stream-banner-card\"],[10,\"style\",[18,\"style\"],null],[7],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"streams\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"      \"],[11,1,[[20,[\"activeStream\"]],[25,\"hash\",null,[[\"header\",\"details\",\"controls\"],[[25,\"component\",[\"stream-banner/station\"],[[\"activeStream\",\"streams\"],[[20,[\"activeStream\"]],[20,[\"streams\"]]]]],[25,\"component\",[\"stream-banner/details\"],[[\"activeStream\"],[[20,[\"activeStream\"]]]]],[25,\"component\",[\"stream-banner/controls\"],[[\"activeStream\"],[[20,[\"activeStream\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Loading...\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/stream-banner.hbs"
    }
  });

  _exports.default = _default;
});