define("nypr-ui/templates/components/support-stripe/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N+H26DYS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[22,1]],null,{\"statements\":[[0,\"  \"],[1,[18,\"text\"],false],[1,[25,\"nypr-svg\",null,[[\"icon\",\"className\"],[\"double-arrow\",\"support-stripe__arrow\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"log\",[\"usage with block is undefined\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/support-stripe/label.hbs"
    }
  });

  _exports.default = _default;
});