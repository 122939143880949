define("nypr-publisher-lib/templates/components/stream-playlist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ygYxaYBG",
    "block": "{\"symbols\":[\"playlistItem\"],\"statements\":[[6,\"div\"],[9,\"class\",\"streaminfo\"],[7],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",[18,\"homepage\"],null],[7],[6,\"img\"],[9,\"class\",\"streaminfo-logo\"],[10,\"src\",[26,[[20,[\"stream\",\"imageLogo\"]]]]],[7],[8],[8],[0,\"\\n  \"],[6,\"h2\"],[9,\"class\",\"streaminfo-heading\"],[7],[0,\"Music Playlist\"],[8],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"streaminfo-name\"],[7],[1,[20,[\"stream\",\"name\"]],false],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"streaminfo-currentshow\"],[7],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",[26,[[20,[\"stream\",\"currentShow\",\"url\"]]]]],[7],[1,[20,[\"stream\",\"currentShow\",\"title\"]],false],[8],[0,\" until \"],[1,[25,\"hide-zero-minutes\",[[20,[\"stream\",\"currentShow\",\"end\"]]],null],false],[0,\".\\n  \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"stream\",\"currentPlaylistItem\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"playlistitem playlistitem--nowplaying\"],[7],[0,\"\\n    \"],[1,[25,\"playlist-item\",null,[[\"nowPlaying\",\"item\"],[true,[20,[\"stream\",\"currentPlaylistItem\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[20,[\"historyItems\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"playlistitem\"],[7],[0,\"\\n    \"],[1,[25,\"playlist-item\",null,[[\"item\"],[[19,1,[]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[6,\"a\"],[9,\"class\",\"streaminfo-playlistlink\"],[10,\"href\",[26,[[20,[\"stream\",\"playlistUrl\"]]]]],[7],[0,\"View Full Playlist »\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-publisher-lib/templates/components/stream-playlist.hbs"
    }
  });

  _exports.default = _default;
});