define("nypr-ui/templates/components/nypr-ui/brand-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L6r667m6",
    "block": "{\"symbols\":[\"burger\",\"menu\",\"item\",\"&default\"],\"statements\":[[4,\"if\",[[22,4]],null,{\"statements\":[[0,\"  \"],[11,4,[[25,\"hash\",null,[[\"header\"],[[25,\"component\",[\"nypr-ui/brand-header/header\"],[[\"menuItems\",\"open\"],[[20,[\"menuItems\"]],[20,[\"open\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"nypr-ui/brand-header/header\",null,[[\"menuItems\",\"open\"],[[20,[\"menuItems\"]],[20,[\"open\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[6,\"div\"],[10,\"class\",[26,[\"nypr-brand-header-overlay-bg \",[25,\"if\",[[20,[\"open\"]],\"active\"],null]]]],[7],[8],[0,\"\\n\"],[4,\"burger-menu\",null,[[\"class\",\"aria-hidden\",\"width\",\"open\",\"position\"],[\"nypr-brand-header-menu\",[25,\"if\",[[20,[\"open\"]],\"false\",\"true\"],null],200,[20,[\"open\"]],\"right\"]],{\"statements\":[[4,\"component\",[[19,1,[\"menu\"]]],[[\"dismissOnItemClick\",\"itemTagName\"],[true,\"li\"]],{\"statements\":[[0,\"    \"],[6,\"a\"],[9,\"role\",\"button\"],[9,\"class\",\"nypr-brand-header-button nypr-brand-header-close\"],[3,\"action\",[[19,0,[]],[19,1,[\"state\",\"actions\",\"close\"]]]],[7],[6,\"span\"],[9,\"aria-label\",\"close navigation\"],[7],[0,\"NYPR Network\"],[8],[0,\"\\n      \"],[1,[25,\"nypr-svg\",null,[[\"className\",\"icon\"],[\"nypr-brand-header-close-icon\",\"close\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"ul\"],[9,\"class\",\"nypr-brand-header-menu-items\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"menuItems\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"logo-link\",null,[[\"logo\"],[[19,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-ui/brand-header.hbs"
    }
  });

  _exports.default = _default;
});