define("nypr-player/templates/components/nypr-player/queue-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CjX276Uh",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"nypr-player-queue-icon mod-open\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-queue-bar mod-animate\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-queue-dot mod-animate\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-queue-dot\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-queue-dot\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-queue-dot\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-queue-bar\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-queue-bar\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"nypr-player-queue-bar\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[25,\"nypr-svg\",null,[[\"icon\",\"className\"],[\"close\",\"nypr-player-icon nypr-player-queue-icon mod-close\"]]],false],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"nypr-player-queue-label\"],[7],[0,\"Queue\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-player/templates/components/nypr-player/queue-button.hbs"
    }
  });

  _exports.default = _default;
});