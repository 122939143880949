define("nypr-account-settings/templates/components/nypr-accounts/social-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FNBYlJlv",
    "block": "{\"symbols\":[\"card\",\"&default\"],\"statements\":[[4,\"nypr-card\",null,[[\"class\"],[\"nypr-social-card\"]],{\"statements\":[[0,\"\\n  \"],[1,[25,\"component\",[[19,1,[\"header\"]]],[[\"class\",\"title\"],[\"nypr-social-card__header\",\"Social\"]]],false],[0,\"\\n\\n  \"],[11,2,[[25,\"hash\",null,[[\"connect-button\"],[[25,\"component\",[\"nypr-accounts/social-connect-button\"],null]]]]]],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-account-settings/templates/components/nypr-accounts/social-card.hbs"
    }
  });

  _exports.default = _default;
});