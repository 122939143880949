define("nypr-audio-services/templates/components/nypr-player-integration/stream-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/3vERKcR",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"nypr-player-stream-info-station-info\"],[7],[0,\"\\n  LIVE NOW on \"],[6,\"a\"],[10,\"href\",[18,\"streamScheduleUrl\"],null],[10,\"target\",[25,\"if\",[[25,\"is-external\",[[20,[\"streamScheduleUrl\"]]],null],\"_blank\"],null],null],[10,\"rel\",[25,\"if\",[[25,\"is-external\",[[20,[\"streamScheduleUrl\"]]],null],\"noopener\"],null],null],[9,\"class\",\"gtm__click-tracking\"],[9,\"data-action\",\"Click Through to Schedule\"],[9,\"data-label\",\"player\"],[7],[1,[18,\"streamName\"],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"nypr-player-stream-info-station-links\"],[7],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",[18,\"streamScheduleUrl\"],null],[9,\"title\",\"Schedule\"],[10,\"target\",[25,\"if\",[[25,\"is-external\",[[20,[\"streamScheduleUrl\"]]],null],\"_blank\"],null],null],[10,\"rel\",[25,\"if\",[[25,\"is-external\",[[20,[\"streamScheduleUrl\"]]],null],\"noopener\"],null],null],[9,\"class\",\"gtm__click-tracking\"],[9,\"data-action\",\"Click Through to Schedule\"],[9,\"data-label\",\"player\"],[7],[0,\"SCHEDULE\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"streamPlaylistUrl\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"href\",[18,\"streamPlaylistUrl\"],null],[9,\"title\",\"Music Play History\"],[9,\"class\",\"gtm__click-tracking\"],[9,\"data-action\",\"Click Through to Music Play History\"],[9,\"data-label\",\"player\"],[7],[0,\"MUSIC PLAY HISTORY\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[4,\"link-to\",[\"stream\",[25,\"query-params\",null,[[\"modal\"],[null]]]],[[\"title\"],[\"All Live Streams\"]],{\"statements\":[[0,\"ALL LIVE STREAMS\"]],\"parameters\":[]},null],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-audio-services/templates/components/nypr-player-integration/stream-info.hbs"
    }
  });

  _exports.default = _default;
});