define("nypr-ui/templates/components/nypr-multi-card/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qnrkXyuQ",
    "block": "{\"symbols\":[\"card\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"active\"]]],null,{\"statements\":[[4,\"nypr-card\",null,null,{\"statements\":[[0,\"    \"],[11,2,[[25,\"hash\",null,[[\"button\",\"header\",\"alert\"],[[25,\"component\",[\"nypr-card/button\"],[[\"click\"],[[25,\"action\",[[19,0,[]],\"goTo\",[20,[\"to\"]]],null]]]],[25,\"component\",[[25,\"get\",[[19,1,[]],\"header\"],null]],[[\"title\"],[[20,[\"title\"]]]]],[25,\"component\",[\"nypr-card/alert\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-multi-card/panel.hbs"
    }
  });

  _exports.default = _default;
});