define("nypr-ui/templates/components/nypr-brick-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9+Oqrb3r",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"blowout\",\"cards\"],[[25,\"component\",[\"nypr-brick-row-blowout\"],null],[25,\"component\",[\"nypr-brick-card-row\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nypr-ui/templates/components/nypr-brick-layout.hbs"
    }
  });

  _exports.default = _default;
});